import '../style.css';
import Quote from "./subcomponents/Quote.js";
import PageText from "./subcomponents/PageText";
import {gebi, gecla} from "../Utils.js";

import pic from "../images/PicFotografie.webp";

import s1 from "../images/photos_sm/s1.webp";
import s2 from "../images/photos_sm/s2.webp";
import s3 from "../images/photos_sm/s3.webp";
import s4 from "../images/photos_sm/s4.webp";
import s5 from "../images/photos_sm/s5.webp";
import s6 from "../images/photos_sm/s6.webp";
import s7 from "../images/photos_sm/s7.webp";
import s8 from "../images/photos_sm/s8.webp";
import s9 from "../images/photos_sm/s9.webp";
import s10 from "../images/photos_sm/s10.webp";
import s11 from "../images/photos_sm/s11.webp";
import s12 from "../images/photos_sm/s12.webp";
import s13 from "../images/photos_sm/s13.webp";
import s14 from "../images/photos_sm/s14.webp";
import s15 from "../images/photos_sm/s15.webp";
import s16 from "../images/photos_sm/s16.webp";
import l1 from "../images/photos_sm/l1.webp";
import l2 from "../images/photos_sm/l2.webp";
//import l3 from "../images/photos_sm/l3.webp";
import l4 from "../images/photos_sm/l4.webp";
import l5 from "../images/photos_sm/l5.webp";
import l6 from "../images/photos_sm/l6.webp";
import l7 from "../images/photos_sm/l7.webp";
// import l8 from "../images/photos_sm/l8.webp";
import l9 from "../images/photos_sm/l9.webp";
//import p1 from "../images/photos_sm/p1.webp";
//import p2 from "../images/photos_sm/p2.webp";
// import p3 from "../images/photos_sm/p3.webp";
import p4 from "../images/photos_sm/p4.webp";
// import p5 from "../images/photos_sm/p5.webp";
// import p6 from "../images/photos_sm/p6.webp";
import p7 from "../images/photos_sm/p7.webp";
import p8 from "../images/photos_sm/p8.webp";
import p9 from "../images/photos_sm/p9.webp";
import p10 from "../images/photos_sm/p10.webp";

import s1b from "../images/photos_big/s1.webp";
import s2b from "../images/photos_big/s2.webp";
import s3b from "../images/photos_big/s3.webp";
import s4b from "../images/photos_big/s4.webp";
import s5b from "../images/photos_big/s5.webp";
import s6b from "../images/photos_big/s6.webp";
import s7b from "../images/photos_big/s7.webp";
import s8b from "../images/photos_big/s8.webp";
import s9b from "../images/photos_big/s9.webp";
import s10b from "../images/photos_big/s10.webp";
import s11b from "../images/photos_big/s11.webp";
import s12b from "../images/photos_big/s12.webp";
import s13b from "../images/photos_big/s13.webp";
import s14b from "../images/photos_big/s14.webp";
import s15b from "../images/photos_big/s15.webp";
import s16b from "../images/photos_big/s16.webp";
import l1b from "../images/photos_big/l1.webp";
import l2b from "../images/photos_big/l2.webp";
//import l3b from "../images/photos_big/l3.webp";
import l4b from "../images/photos_big/l4.webp";
import l5b from "../images/photos_big/l5.webp";
import l6b from "../images/photos_big/l6.webp";
import l7b from "../images/photos_big/l7.webp";
// import l8b from "../images/photos_big/l8.webp";
import l9b from "../images/photos_big/l9.webp";
//import p1b from "../images/photos_big/p1.webp";
//import p2b from "../images/photos_big/p2.webp";
// import p3b from "../images/photos_big/p3.webp";
import p4b from "../images/photos_big/p4.webp";
// import p5b from "../images/photos_big/p5.webp";
//import p6b from "../images/photos_big/p6.webp";
import p7b from "../images/photos_big/p7.webp";
import p8b from "../images/photos_big/p8.webp";
import p9b from "../images/photos_big/p9.webp";
import p10b from "../images/photos_big/p10.webp";

function Fotografie( {text} ) {
    text.shadow = "0px 0px 5px " + text.color;
    return (
        <div id="fotografie" name={text.name} className="page column alCent">
            <div className="pageStart column justCent alCent full">
                <Quote text={text} />
                <img className="pic marginBottom" src={pic} alt={text.altImage} style={{"borderColor": text.color, "boxShadow": text.shadow}}/>
            </div>
            <PageText text={text} />
            <div className="flex alCent justCent transparent marginBottom">
                <p className="textCent">{text.info1}</p>
                <a className="link click textCent" target="_blank" rel="noreferrer" href="https://www.instagram.com/tobis_street/">tobis-street</a>
            </div>
            <div className="picContainer marginBottom">
                <Picture src={s13} name={s13b} text={text.info} />
                <Picture src={s1} name={s1b} text={text.info} />
                <Picture src={p8} name={p8b} text={text.info} />
                <Picture src={l1} name={l1b} text={text.info} />
                <Picture src={s2} name={s2b} text={text.info} />
                <Picture src={s14} name={s14b} text={text.info} />
                <Picture src={s3} name={s3b} text={text.info} />
                <Picture src={l2} name={l2b} text={text.info} />
                <Picture src={s11} name={s11b} text={text.info} />
                <Picture src={s6} name={s6b} text={text.info} />
                <Picture src={l9} name={l9b} text={text.info} />
                <Picture src={p9} name={p9b} text={text.info} />
                <Picture src={s10} name={s10b} text={text.info} />
                <Picture src={s4} name={s4b} text={text.info} />
                <Picture src={s12} name={s12b} text={text.info} />
                <Picture src={l4} name={l4b} text={text.info} />
                <Picture src={s5} name={s5b} text={text.info} />
                <Picture src={s7} name={s7b} text={text.info} />
                <Picture src={s8} name={s8b} text={text.info} />
                <Picture src={l6} name={l6b} text={text.info} />
                <Picture src={s9} name={s9b} text={text.info} />
                <Picture src={p10} name={p10b} text={text.info} />
                <Picture src={l5} name={l5b} text={text.info} />
                <Picture src={p7} name={p7b} text={text.info} />
                <Picture src={p4} name={p4b} text={text.info} />
                <Picture src={l7} name={l7b} text={text.info} />
                <Picture src={s15} name={s15b} text={text.info} />
                <Picture src={s16} name={s16b} text={text.info} />
            </div>
            <hr className="marginBottom marginTop" style={{"width":"90vw", "color": "rgba(0,0,0,0.6)"}} />
        </div>
    )
}

function Picture({src, name, text}){

    const showPicture = (name) => {
        const newFoto = document.createElement("div");
        newFoto.setAttribute("class", "picBig column justCent alCent click");
        newFoto.onclick = function() {gecla("picBig").remove();}

        const imageElement = document.createElement("img");
        imageElement.setAttribute("src", name);
        imageElement.setAttribute("alt", text.altBigImage)
        newFoto.appendChild(imageElement);

        const infoText = document.createElement("p");
        infoText.setAttribute("class", "picBigInfo");
        infoText.textContent = text;
        newFoto.appendChild(infoText);

        gebi("fotografie").appendChild(newFoto);
    }
    

    return(
        <img className="picSmall click" onClick={()=>showPicture(name)} src={src} alt={"foto "+name} />
    )
}

export default Fotografie
