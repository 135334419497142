import React from 'react'
import '../../style.css';

import Vcontent5 from "../../videos/content5.mp4";
import Vsound1 from "../../videos/sound1.mp4";
import Vsound2 from "../../videos/sound2.mp4";
import Vsound3 from "../../videos/sound3.mp4";
import Vsound4 from "../../videos/sound4.mp4";
import Vsound5 from "../../videos/sound5.mp4";
import Vsound6 from "../../videos/sound6.mp4";
import Vmodeling1 from "../../videos/modeling1.mp4";
import Vmodeling2 from "../../videos/modeling2.mp4";
import Vmodeling3 from "../../videos/modeling3.mp4";
import Vmodeling4 from "../../videos/modeling4.mp4";
import Vmodeling5 from "../../videos/modeling5.mp4";
import Vother1 from "../../videos/other1.mp4";
import Vother3 from "../../videos/other3.mp4";
import Vother4 from "../../videos/other4.mp4";
import Vother7DE from "../../videos/other7DE.mp4";
import Vother7EN from "../../videos/other7EN.mp4";
import Vother8DE from "../../videos/other8DE.mp4";
import Vother8EN from "../../videos/other8EN.mp4";

import Pcontent1 from "../../images/content1.webp";
import Pcontent5 from "../../images/content5poster.webp";
import Psound1 from "../../images/sound1poster.webp";
import Psound2 from "../../images/sound2poster.webp";
import Psound3 from "../../images/sound3poster.webp";
import Psound4 from "../../images/sound4poster.webp";
import Psound5 from "../../images/sound5poster.webp";
import Pmodeling1 from "../../images/modeling1poster.webp";
import Pmodeling2 from "../../images/modeling2poster.webp";
import Pmodeling3 from "../../images/modeling3poster.webp";
import Pmodeling4 from "../../images/modeling4poster.webp";
import Pmodeling5 from "../../images/modeling5poster.webp";
import Pother1 from "../../images/other1poster.webp";
import Pother3 from "../../images/other3poster.webp";
import Pother4 from "../../images/other4poster.webp";
import Pother7 from "../../images/other7poster.webp";
import Pother8 from "../../images/other8poster.webp";

import Iother1 from "../../images/other2.webp";
import Icontent2 from "../../images/content2.webp";
import Icontent3 from "../../images/content3.webp";
import Icontent4 from "../../images/content4.webp";
import Imodeling6 from "../../images/modeling6.webp";
import Isound6 from "../../images/sound6.webp";

function ProjectItem( {text} ) {
    let projectContent = [];
    for ( let i = 0 ; i < text.content.length; i++){
        projectContent.push(<p key={i}>{text.content[i]}</p>)
    }

    let vidSource; let postSource; let picSource;
    switch (text.VideoSource) {
        case("content5"): default: vidSource = Vcontent5; break;
        case("sound1"): vidSource = Vsound1; break;
        case("sound2"): vidSource = Vsound2; break;
        case("sound3"): vidSource = Vsound3; break;
        case("sound4"): vidSource = Vsound4; break;
        case("sound5"): vidSource = Vsound5; break;
        case("modeling1"): vidSource = Vmodeling1; break;
        case("modeling2"): vidSource = Vmodeling2; break;
        case("modeling3"): vidSource = Vmodeling3; break;
        case("modeling4"): vidSource = Vmodeling4; break;
        case("modeling5"): vidSource = Vmodeling5; break;
        case("other1"): vidSource = Vother1; break;
        case("other3"): vidSource = Vother3; break;
        case("other4"): vidSource = Vother4; break;
        case("other7DE"): vidSource = Vother7DE; break;
        case("other7EN"): vidSource = Vother7EN; break;
        case("other8DE"): vidSource = Vother8DE; break;
        case("other8EN"): vidSource = Vother8EN; break;
    }
    switch (text.PosterSource) {
        case("content5"): default: postSource = Pcontent5; break;
        case("sound1"): postSource = Psound1; break;
        case("sound2"): postSource = Psound2; break;
        case("sound3"): postSource = Psound3; break;
        case("sound4"): postSource = Psound4; break;
        case("sound5"): postSource = Psound5; break;
        case("modeling1"): postSource = Pmodeling1; break;
        case("modeling2"): postSource = Pmodeling2; break;
        case("modeling3"): postSource = Pmodeling3; break;
        case("modeling4"): postSource = Pmodeling4; break;
        case("modeling5"): postSource = Pmodeling5; break;
        case("other1"): postSource = Pother1; break;
        case("other3"): postSource = Pother3; break;
        case("other4"): postSource = Pother4; break;
        case("other7"): postSource = Pother7; break;
        case("other8"): postSource = Pother8; break;
    }

    switch(text.PictureSource) {
        case("content1"): default: picSource = Pcontent1; break;
        case ("content2"): picSource = Icontent2; break;
        case ("content3"): picSource = Icontent3; break;
        case ("content4"): picSource = Icontent4; break;
        case ("modeling6"): picSource = Imodeling6; break;
        case ("sound6"): picSource = Isound6; break;
        case ("other2"): picSource = Iother1; break;
    }


    return (
        <div id={text.id} className="projectItem column alCent justBet">
                <h3 className="marginTop headerFont">{text.name}</h3>
                {projectContent}
                {text.link !== undefined ? <a className="link click" target="_blank" rel="noreferrer" href={text.linkRef}>{text.link}</a> : null}
            {text.VideoSource !== undefined ? 
                <video 
                    className="projectVideo click" 
                    controlsList="nodownload noplaybackrate"
                    disablePictureInPicture 
                    controls 
                    poster={postSource} 
                    src={vidSource} 
                    preload="none" /> 
            : null}
            {text.PictureSource !== undefined ? <img className="projectVideo projectPic marginTop" src={picSource} alt={text.name}/> : null}
            <hr className="noDesktop" style={{"width":"70vw", "color": "rgba(0,0,0,0.1)"}} />
        </div>
    )
}

export default ProjectItem
