import React from 'react'
import '../style.css';
import Quote from "./subcomponents/Quote.js";
import PageText from "./subcomponents/PageText";
import ProjectItem from "./subcomponents/ProjectItem.js";

import pic from "../images/PicOther.webp";

function Sonstige({text}) {
    text.shadow = "0px 0px 5px " + text.color;
    return (
        <div id="sonstige" name={text.name}  className="page column alCent">
            <div className="pageStart column justCent alCent full">
                <Quote text={text} />
                <img className="pic marginBottom" src={pic} alt={text.altImage} style={{"borderColor":text.color, "boxShadow": text.shadow}}/> 
            </div>
            <PageText text={text} />
            <hr className="marginBottom" style={{"width":"70vw", "color": "rgba(0,0,0,0.1)", "marginLeft":"auto", "marginRight":"auto"}} />
            <div className="projectContainer column justCent" style={{marginBottom:20}}>

                {/* FPV */}
                <ProjectItem text={text.projekte[3]}/>

                {/* Musik Showreel */}
                <ProjectItem text={text.projekte[0]}/>

                {/* Website */}
                <ProjectItem text={text.projekte[5]}/>

                {/* CoinFlip */}
                <ProjectItem text={text.projekte[6]}/>

                {/* Pömpel */}
                <ProjectItem text={text.projekte[2]}/>

                {/* SonnenstundenWien 60 */}
                <ProjectItem text={text.projekte[1]}/>
            </div>
        </div>
    )
}

export default Sonstige
