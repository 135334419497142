import React, {useState, useEffect, useRef} from 'react';
import {Scrollbars} from "react-custom-scrollbars";
import {gecla} from "./Utils.js";
import {Helmet} from "react-helmet";

import './style.css';

import Navbar from "./components/Navbar.js";
import Start from "./components/Start.js";
import Audio from "./components/Audio.js";
import Content from "./components/Content.js"
import Darsteller from "./components/Darsteller.js";
import Fotografie from "./components/Fotografie.js";
import Sonstige from "./components/Sonstige.js";


function App() {
  const textDe = require("./data/de.json");
  const textEn = require("./data/en.json");
  const [text, setText] = useState(textDe);

  const scrollbar = useRef(null);
  const [scrollHeight, setScrollHeight] = useState("0px");
  const checkScrollHeight = () => {gecla("navOnlyMobile").clientHeight === 0 ? setScrollHeight("60px") : setScrollHeight("122px");}
  useEffect(() => {checkScrollHeight();})

  let resizeTimer;
  window.addEventListener("resize", () => {
    checkScrollHeight();
    document.body.classList.add("resize-animation-stopper");
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
    document.body.classList.remove("resize-animation-stopper");
  }, 400);
  });

  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: 'rgba(0,0,0,0.6)'
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };


  return (
    <div id="wrapper" className="flex column alCent marginBottom">
      <Helmet>
        <title>{text.Meta.title}</title>
      </Helmet>
      <h1>Tobias Schreiber</h1>
      <Navbar text={text.Nav} setText={setText} textDe={textDe} textEn={textEn} scrollbar={scrollbar}/>
        <div id="pageWrapper">
        <Scrollbars ref={scrollbar} autoHide autoHideTimeout={500} autoHideDuration={500} 
          style={{"height": "calc(100vh - " + scrollHeight + ")" }}
          renderThumbHorizontal={renderThumb} renderThumbVertical={renderThumb} >
          <Start text={text.Start} />
          <Content text={text.Content} />
          <Darsteller text={text.Darsteller} />
          <Audio text={text.Audio} />
          <Fotografie text={text.Fotografie} />
          <Sonstige text={text.Sonstige} />
        </Scrollbars>
        </div>
    </div>
  );
}

export default App;
