import React from 'react'
import '../../style.css';

function Quote( {text} ) {
    return (
        <div className="quote marginBottom marginTop">
            <p>{text.q}</p>
            <p>{text.qo}</p>
        </div>
    )
}

export default Quote
