export const gebi = (element) => {
    return document.getElementById(element);
}
export const gecla = (element) => {
    return document.getElementsByClassName(element)[0];
}
export const query = (element, attribute, parameter) => {
    return document.querySelector(element + "[" + attribute + "='" + parameter +"']");
}

export const addClassName  = (element, name) => {
    element.className = element.className + " " + name;
}
export const removeClassName = (element, name) => {
    element.className = element.className.replace(" " + name, "");
}
export const replaceClassName = (element, oldName, newName) => {
    element.className = element.className.replace(oldName, newName);
}
