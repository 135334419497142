import React from 'react'
import '../../style.css';

function PageText( {text} ) {
    return (
        <div className="pageText textCent marginBottom">
            <h2 className="marginBottom headerFont">{text.h}</h2>
            {text.tj1 !== undefined ? <p className="textJust">{text.tj1}</p> : null} 
            {text.tj2 !== undefined ? <p className="textJust">{text.tj2}</p> : null} 
            {text.tj3 !== undefined ? <p className="textJust">{text.tj3}</p> : null} 
            {text.tj4 !== undefined ? <p className="textJust">{text.tj4}</p> : null} 
            {text.tj4 !== undefined ? <p className="textJust">{text.tj5}</p> : null} 
            {text.t1 !== undefined ? <p className="textCent">{text.t1}</p> : null}
            {text.t2 !== undefined ? <p className="textCent">{text.t2}</p> : null}
            {text.t3 !== undefined ? <p className="textCent">{text.t3}</p> : null}
        </div>
    )
}

export default PageText
