import React from 'react'
import '../style.css';
import Quote from "./subcomponents/Quote.js";
import PageText from "./subcomponents/PageText";

import pic from "../images/PicMain.webp";

function Start( {text} ) {
    text.shadow = "0px 0px 5px " + text.color;
    return (
        <div id="start" name={text.name}  className="page column alCent">
            <div className="pageStart column justCent alCent full">
                <img className="pic marginBottom" src={pic} alt={text.altImage} style={{"borderColor": text.color, "boxShadow": text.shadow}}/> 
                <Quote text={text} />
            </div>
            <PageText text={text} />
            <hr className="marginBottom marginTop" style={{"width":"90vw", "color": "rgba(0,0,0,0.6)"}} />
        </div>
    )
}

export default Start
