import React from 'react';
import '../style.css';
import Quote from "./subcomponents/Quote.js";
import PageText from "./subcomponents/PageText";
import ProjectItem from "./subcomponents/ProjectItem.js";

import pic from "../images/PicContent.webp";

function Content( {text})  {
    text.shadow = "0px 0px 5px " + text.color;
    return (
        <div id="content" name={text.name} className="page column alCent">
            <div className="pageStart column justCent alCent full">
                <Quote text={text} />
                <img className="pic marginBottom" src={pic} alt={text.altImage} style={{"borderColor":text.color, "boxShadow":text.shadow}}/>
            </div>
            <PageText text={text}/>
            <hr className="marginBottom" style={{"width":"70vw", "color": "rgba(0,0,0,0.1)"}} />
            <div className="projectContainer column justCent">

                {/* WienTourismus */}
                <ProjectItem text={text.projekte[0]}/>

                {/* Google Analytics */}
                <ProjectItem text={text.projekte[1]}/>

                {/* MOVE-HP */}
                <ProjectItem text={text.projekte[2]}/>

                {/* Skulpturengarten am Lainzer Tor */}
                <ProjectItem text={text.projekte[3]}/>

                {/* Barcelona Food */}
                <ProjectItem text={text.projekte[4]}/>
                
                
            </div>
            
            <hr className="marginBottom marginTop" style={{"width":"90vw", "color": "rgba(0,0,0,0.6)"}} />
        </div>
    )
}

export default Content
