import React from 'react'
import '../style.css';
import {addClassName, removeClassName, gebi, gecla, query} from "../Utils.js";


import logo from "../images/logo-b-b.webp"

function Navbar( {text, setText, textDe, textEn, scrollbar} ) {

    const changeLang = (lang, langOther, activeClassName, activeClassNameOther) => {
        const element = query("p", "name", lang);
        const elementOther = query("p", "name", langOther);
        const activeElement = gecla("langAct");
        const activeElementM = gecla("langActM");
        if(activeElement.textContent !== lang){
            removeClassName(activeElement, "langAct");
            removeClassName(activeElementM, "langActM");
            addClassName(element, activeClassName);
            addClassName(elementOther, activeClassNameOther);
            switch(lang){
                case "DE": case "DEM": default: setText(textDe); break;
                case "EN": case "ENM": setText(textEn); break;
            }
        }
    }

    return (
        <div id="navbar" className="flex justCent alCent">
            <div className="navOnlyMobile">
                <div className="navRow full row justBet alCent">
                    <p className="lang langActM click" name="DEM" onClick={() => changeLang("DEM", "DE", "langActM", "langAct")}>DE</p>
                    <img id="navLogo" src={logo} alt="Tobias Schreiber Logo" />
                    <p className="lang click" name="ENM" onClick={() => changeLang("ENM", "EN", "langActM", "langAct")}>EN</p>
                </div>
                <hr style={{"width":"90vw", "color": "rgba(0,0,0,0.1)"}} />
                <div className="navRow full row justEven alCent headerFont">
                    <NavEl text={text.nav1} name={text.navName1} scrollbar={scrollbar} />
                    <NavEl text={text.nav2} name={text.navName2} scrollbar={scrollbar} /> 
                    <NavEl text={text.nav3} name={text.navName3} scrollbar={scrollbar} /> 
                    <NavEl text={text.nav4} name={text.navName4} scrollbar={scrollbar} /> 
                    <NavEl text={text.nav5} name={text.navName5} scrollbar={scrollbar} /> 
                    <NavEl text={text.nav6} name={text.navName6} scrollbar={scrollbar} /> 
                </div>
            </div>
            <div className="navNormal row">
                <div className="navRow full row justEven alCent">
                    <p className="lang langAct click" name="DE" onClick={() => changeLang("DE", "DEM", "langAct", "langActM")}>DE</p>
                    <p className="lang click" name="EN" onClick={() => changeLang("EN", "ENM", "langAct", "langActM")}>EN</p>
                    <img id="navLogo" src={logo} alt="logo" />
                </div>
                <div className="navRow full row justEven alCent headerFont">
                    <NavEl text={text.nav1} name={text.navName1} scrollbar={scrollbar} />
                    <NavEl text={text.nav2} name={text.navName2} scrollbar={scrollbar} /> 
                    <NavEl text={text.nav3} name={text.navName3} scrollbar={scrollbar} /> 
                    <NavEl text={text.nav4} name={text.navName4} scrollbar={scrollbar} />
                    <NavEl text={text.nav5} name={text.navName5} scrollbar={scrollbar} /> 
                    <NavEl text={text.nav6} name={text.navName6} scrollbar={scrollbar} /> 
                </div>
            </div>
        </div>
    )
}

function NavEl( {text, name, scrollbar} ) {

    const goToPage = (page) => {
        scrollbar.current.view.scroll({top: gebi(page).offsetTop, behavior: "smooth"});
    }

    return(
        <div className="navEl flex alCent click"  onClick={() => goToPage(name)}>
            <p className="click">{text}</p>
        </div>
    )
}

export default Navbar
