import React from 'react';
import '../style.css';
import Quote from "./subcomponents/Quote.js";
import PageText from "./subcomponents/PageText";
import ProjectItem from "./subcomponents/ProjectItem.js";

import pic from "../images/PicAudio.webp";

function Audio( {text})  {
    text.shadow = "0px 0px 5px " + text.color;
    return (
        <div id="audio" name={text.name} className="page column alCent">
            <div className="pageStart column justCent alCent full">
                <Quote text={text}/>
                <img className="pic marginBottom" src={pic} alt={text.altImage} style={{"borderColor":text.color, "boxShadow": text.shadow}}/>
            </div>
            <PageText text={text}/>
            <hr className="marginBottom" style={{"width":"70vw", "color": "rgba(0,0,0,0.1)"}} />
            <div className="info flex alCent justCent full">
                {text.info1H === undefined ? null : <InfoItem infoH={text.info1H} info={text.info1} />}
                {text.info2H === undefined ? null : <InfoItem infoH={text.info2H} info={text.info2} />}
                {text.info3H === undefined ? null : <InfoItem infoH={text.info3H} info={text.info3} />}
                {text.info4H === undefined ? null : <InfoItem infoH={text.info4H} info={text.info4} />}
                {text.info5H === undefined ? null : <InfoItem infoH={text.info5H} info={text.info5} />}
            </div>
            <hr className="marginTop marginBottom" style={{"width":"70vw"}}/>

            <div className="projectContainer column justCent">

                {/* Leer */}
                <ProjectItem text={text.projekte[5]}/>
                
                {/* Sky Werbung */}
                <ProjectItem text={text.projekte[0]}/>

                {/* EB Urkunde */}
                <ProjectItem text={text.projekte[2]}/>

                {/* Couch Connections Trailer  */}
                <ProjectItem text={text.projekte[3]}/>

                {/* Siemens Mystery Game */}
                <ProjectItem text={text.projekte[1]}/>

                {/* First Date Kurzfilm */}
                <ProjectItem text={text.projekte[4]}/>

            </div>            
            <hr className="marginBottom marginTop" style={{"width":"90vw", "color": "rgba(0,0,0,0.6)"}} />
        </div>
    )
}

function InfoItem  ({infoH, info}) {
return(
    <div className="infoItem flex column alCent">
        <h4 className="headerFont">{infoH}</h4>
        <p>{info}</p>
    </div>
)
}

export default Audio
